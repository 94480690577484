// Dependencies
import React from 'react'
// Hooks
import useMouse from '../hooks/useMouse'

const Clicker = ({ color, children }) => {

  const coords = useMouse();
  const isLeftSide = coords.x <= (window.innerWidth / 2);

  return (
    <div className="clicker h2" style={{
      top: coords.y,
      left: coords.x,
      color: color || "currentColor",
      pointerEvents: "none"
    }}>
      {typeof children !== "function" ? (
        <span>{children}</span>
      ) : children(isLeftSide)}
    </div>
  )
}

export default Clicker
