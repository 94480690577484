import { useState, useEffect, useLayoutEffect } from 'react';

export default function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : '100vw',
      height: isClient ? window.innerHeight : '100vh'
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useLayoutEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}


// const MyComponent = ({ input }) => {
//   const isMounted = useIsMounted()
//   const text = useMemo(() => (
//       isMounted ? expensiveCalculation(input) : undefined
//     ),
//     [isMounted, input])
//   return <SomeChildComponent text={text} />
// }
// const useIsMounted = () => {
//   const [isMounted, setIsMounted] = useState(false)
//   useEffect(() => {
//     setIsMounted(true)
//   }, [])
//   return isMounted
// }
