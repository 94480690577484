// Dependencies
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Img = ({ image, willShow, setLoading }) => {

	const [hasLoaded, set] = useState(false)

	useEffect(() => {
		let timer

		timer = setTimeout(() => {
			if (willShow && !hasLoaded) {
				// !setLoading || setLoading(true)
			}
		}, 400);

		if (hasLoaded) {
			// !setLoading || setLoading(false)
		}

		return () => {
			// !setLoading || setLoading(false)
			clearTimeout(timer)
		}
	}, [willShow, hasLoaded])

	const handleLoad = () => {
		set(true)
	}

	return (
		image ? <GatsbyImage image={image} alt="ANY OTHER NAME" onLoad={handleLoad} /> : null
	)
}

export default Img