// Dependencies
import React, { useState, useRef, useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
// Hooks
import useWindowSize from '../hooks/useWindowSize'
import useTimeout from '../hooks/useTimeout'
import useSettings from '../hooks/useSettings'
// Components
import Slide from '../components/slide'

function useColors() {
  const data = useStaticQuery(graphql`
    {
      allContentfulColors {
        nodes {
          textColour
          backgroundColour
        }
      }
    }
  `)
  return data.allContentfulColors.nodes
}

const Intro = ({ 
	showIntro, 
	setShowIntro, 
	splashIntroText, 
	splash, 
	handleIntroEnd,
	timer
}) => {

  // 
  const colors = useColors();
  const [randomColors] = useState(colors[Math.floor((Math.random() * colors.length))])
	const colours = splash?.colours || randomColors
  // const { height } = useWindowSize();
  const [showTagline, setShowTagline] = useState(false);

  // After 2s, show tagline... NOTE: Have removed content and speed for now...
  useTimeout(() => {
    setShowTagline(true)
  }, 2000, { persistRenders: true });

  // After 5s, hide intro...
  useTimeout(() => {
		if (timer) {
			setShowIntro(false)
		}
  }, (timer || 0), { persistRenders: true });

	const handleSlideTimer = () => {
		setShowIntro(false)
	}

  return (
    <div className={`intro ${!showIntro ? 'hide' : ''}`} style={{
      // height: showIntro ? height : 0,
      backgroundColor: colours.backgroundColour,
      color: colours.textColour
    }} onClick={() => setShowIntro(false)} onTransitionEnd={handleIntroEnd}>
			{splash && (
				<Slide 
					content={splash}
					className="intro__asset cover"
					ready={true} 
					isActive={true} 
					setLoading={false}
					callback={handleSlideTimer}
				/>
			)}
			
			{splashIntroText && (
				<div className={`tagline ${showTagline ? 'show' : 'hide'}`} style={{
					opacity: showIntro ? 1 : 0
				}}>
					<div></div>
					<div>{splashIntroText}</div>
				</div>
			)}
    </div>
  )
}

export default Intro
