import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulColors {
        nodes {
          textColour
          backgroundColour
        }
      }
      allContentfulSettings {
        nodes {
          ## Metaaaa
          metaTitle
          metaDescription
          metaImage {
            fluid(maxWidth: 600) {
              src
            }
          }
          ## Default tagline for intro
          tagline
          ## Copy for about page overlay
          about {
            childMarkdownRemark {
              html
            }
          }
          servicesClients {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
