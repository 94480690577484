// Dependencies
import React, { useState, useEffect } from 'react'
// Components
import About from '../components/about'
import CookieNotice from '../components/cookieNotice'
import Head from '../components/head'
import Intro from '../components/intro'
// CSS
import '../css/app.scss'

const Layout = ({ css, hasIntro, invert, introText, splash, splashIntroText, introTimer, children }) => {

  const [showAbout, setShowAbout] = useState(false);
  const [showAboutButton, setShowAboutButton] = useState(false);
  const [showIntro, setShowIntro] = useState(hasIntro);
	const [renderIntro, setRenderIntro] = useState(hasIntro);

  useEffect(() => {

    if (!showIntro) {
      setShowAboutButton(true)
    }
  }, [hasIntro, showIntro])

	const handleIntroEnd = (event) => {
		if (event.propertyName === 'transform' && !showIntro) {
			setRenderIntro(false)
		}
	}

  const buttonColor = invert ? (showAbout ? '' : 'invert') : (showAbout ? 'invert' : '');

  return (
    <main>
      <Head css={css} />
      {renderIntro && (
				<Intro 
					introText={introText} 
					showIntro={showIntro} 
					setShowIntro={setShowIntro} 
					handleIntroEnd={handleIntroEnd} 
					splash={splash} 
					splashIntroText={splashIntroText}
					timer={introTimer}
				/>
			)}
      {children(setShowAboutButton, showIntro)}
      <About show={showAbout} setShowAbout={setShowAbout} invert={invert} />

      <button
        className={`about-button padx2 ${showIntro ? 'has-intro' : ''} ${showAboutButton ? 'show' : ''} ${buttonColor}`}
        onClick={() => setShowAbout(o => !o)}
      >
        <span className={`${showAbout ? 'close-label' : 'info-label'}`} />
      </button>

      <CookieNotice />
    </main>
  )
}

export default Layout
