import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import useSettings from '../hooks/useSettings';

function Head({ description, lang, meta, title, css }) {

  const content = useSettings();
  const metaTitle = title || content.metaTitle;
  const metaDescription = description || content.metaDescription;
  const metaImage = content.metaImage ? content.metaImage.fluid.src : "";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@minkmgmt`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      style={[{
        "cssText": css || ``
      }]}
    />
  )
}

Head.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Head
