// Dependencies
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// Hooks
import useWindowSize from '../hooks/useWindowSize'
// Components
import Clicker from '../components/clicker'
import Img from '../components/img'
import Layout from '../components/layout'
import Markdown from '../components/markdown'

const DEFAULT_THEME = {
  textColour: '#FFFFFF',
  backgroundColour: '#000000'
};

const Video = ({ 
	className = "fs", 
	src, 
	poster, 
	shouldPlay, 
	isActive,
	activeIndex 
}) => {

	const ref = useRef()
	// const [mounted, setMounted] = useState(false)
	const [willPlay, set] = useState(false)

	// useEffect(() => {
	// 	setMounted(true)
	// }, [])

	const handlePlay = (event) => {
		set(true)
	}

	const handleTouchStart = (event) => {
    event.target.play()
  }

	// const handleProgress = event => {
	// 	if (ref.current) {
	// 		if (ref.current.currentTime > 0) {
	// 			set(true)
	// 		}
	// 	}
	// }

	useEffect(() => {
		if (ref.current && isActive) {
			ref.current.currentTime = 0
		}
	}, [isActive])

	const style = {
		opacity: isActive ? 1 : !poster ? 1 : 0,
		transition: 'opacity 0.3s'
	}

	return (
		<video
			className={className}
			src={src}
			autoPlay
			muted
			loop
			playsInline
			controls={false}
			onPlaying={handlePlay}
			onTouchStart={handleTouchStart}
			// onProgress={handleProgress}
			style={style}
			ref={ref}
		/>
	)
}

const Iframe = ({ src }) => {
  return(
    <iframe src={src} frameBorder="0"/>
  )
}

const Asset = ({ 
	asset, 
	video, 
	image, 
	iframe, 
	shouldPlay, 
	activeIndex, 
	setLoading,
	isActive
}) => {

	return (
		<div className="slide-asset">
			{image && <Img image={image.gatsbyImageData} setLoading={setLoading} willShow={shouldPlay} />}
			{video && <Video className="fs" src={video.file.url} poster={image?.file.url} shouldPlay={shouldPlay} activeIndex={activeIndex} isActive={isActive} />}
			{iframe && <Iframe src={iframe} />}
		</div>
	)
	// return (
	// 	iframe ? (
	// 		<Iframe src={iframe} />
	// 	) : video ? (
	// 		<Video className="fs" src={video.file.url} poster={image?.file.url} shouldPlay={shouldPlay} activeIndex={activeIndex} />
	// 	) : image ? (
	// 		<Img image={image.gatsbyImageData} setLoading={setLoading} willShow={shouldPlay} />
	// 	) : (
	// 		<div className="debug" />
	// 	)
	// )
}

const SlideProgress = ({ children, duration }) => {

  return (
    <div className="inlb prel">
      <span>{children}</span>
      <div className="slide-progress progress-bar" style={{
				animation: `progress ${duration}ms linear`,
				animationDelay: `0ms`
			}} />
    </div>
  )
}

const AssetGallery = ({ 
	slides, 
	assets, 
	iframe, 
	shouldPlay, 
	interval, 
	setLoading, 
	isActive,
	callback 
}) => {

	const [activeIndex, setActiveIndex] = useState(0)

	useEffect(() => {
		let timer
		// console.log('run')
		const nextSlide = () => {
			setActiveIndex(prev => {
				return prev < slides.length -1 ? prev + 1 : prev
			})
			timer = setTimeout(nextSlide, interval)
			!callback || callback()
		}

		if (shouldPlay) {
			clearTimeout(timer)
			timer = setTimeout(nextSlide, interval)
		} else {
			clearTimeout(timer)
		}
		return () => clearTimeout(timer)
	}, [shouldPlay])

	return (
		slides.map((slide, index) => index === activeIndex && (
			<Asset
				image={slide.image}
				video={slide.video}
				iframe={iframe}
				shouldPlay={shouldPlay}
				activeIndex={activeIndex}
				setLoading={setLoading}
				isActive={isActive}
				key={slide.contentful_id}
			/>
		))
	)
}

export function useSlideInfo(slide) {

	const DEFAULT_SPEED = 10000;

	const {
		client,
		description,
		asset,
		assets,
		slideTimeDesktop,
		assetMobile,
		assetsMobile,
		slideTimeMobile,
    iframeLink,
		iFrameLinkMobile,
		colours,
		slides,
	} = slide

	const desktopSlides = slides?.map(slide => {
		return { 
			contentful_id: slide.contentful_id,
			image: slide.imageLandscape, 
			video: slide.videoLandscape 
		}
	})
	const mobileSlides = slides?.map(slide => {
		return { 
			contentful_id: slide.contentful_id,
			image: slide.imagePortrait || slide.imageLandscape, 
			video: slide.videoPortrait || slide.videoLandscape 
		}
	})
	const desktopAssets = assets || [asset] || []
	const mobileAssets = assetsMobile || [assetMobile || asset] || []
	const desktopInterval = (slideTimeDesktop || DEFAULT_SPEED) / desktopAssets.length
	const mobileInterval = (slideTimeMobile || DEFAULT_SPEED) / mobileAssets.length

	// ---------------------------------------
	// <HomepageGallery /> timing, and <AssetGallery /> timing...
	const [galleryInterval, setGalleryInterval] = useState(slideTimeDesktop)
	const [slideInterval, setSlideInterval] = useState(desktopInterval)

	useEffect(() => {
		if (window.matchMedia("(orientation: portrait)").matches) {
			setSlideInterval(mobileInterval)
			setGalleryInterval(slideTimeMobile || DEFAULT_SPEED)
		} else {
			setSlideInterval(desktopInterval)
			setGalleryInterval(slideTimeDesktop || DEFAULT_SPEED)
		}
	}, [slide])

	return { 
		slides, 
		desktopSlides, 
		mobileSlides, 
		desktopAssets, 
		mobileAssets, 
		galleryInterval, 
		slideInterval, 
		iframeLink, 
		iFrameLinkMobile, 
		client, 
		description, 
		colours, 
		slide 
	}
}

const Slide = (props) => {

  const {
		content,
		className,
		isActive,
		isNext,
		counter,
		setCurrentColours,
		ready,
		setLoading,
		callback
	} = props;

	const {
		desktopAssets,
		mobileAssets,
		desktopSlides, 
		mobileSlides, 
		galleryInterval,
		slideInterval,
    iframeLink,
		iFrameLinkMobile,
		client,
		description,
		colours,
		slides
	} = useSlideInfo(content)

  const updateColours = useCallback(() => {
    if (isActive && setCurrentColours) {
      !setCurrentColours || setCurrentColours(colours || DEFAULT_THEME)
    }
  }, [isActive, colours])

  useEffect(updateColours, [isActive])

	const sharedProps = {
		callback: callback,
		shouldPlay: ready && (isActive || isNext),
		isActive: isActive,
		setLoading: setLoading,
		interval: slideInterval
	}

  return (
    (desktopSlides || mobileSlides) ? (
      <div className={className || `homepage-gallery-slide cover`} style={{
        display: (isActive || isNext) ? 'block' : 'none',
				opacity: isActive ? 1 : 0,
				zIndex: isActive ? 3 : 2
      }}>
				{client && description && ready && isActive && (
					<div className="info h2 padx2 grid">
						<div className="col-3">
							<SlideProgress duration={galleryInterval}>
								{`ANY OTHER NAME for `}<span className="upcase">{client.name}</span>
							</SlideProgress>
						</div>
						<div className="col-3 project-description">
							<span className="counter">{counter}&emsp;</span>
							<Markdown field={description} />
						</div>
					</div>
				)}
				
				{(isActive || isNext) && ready && (
					<>
						<div className="prel landscape fs">
							<AssetGallery slides={desktopSlides} assets={desktopAssets} iframe={iframeLink} {...sharedProps} />
						</div>
						<div className="prel portrait fs">
							<AssetGallery slides={mobileSlides} assets={mobileAssets} iframe={iFrameLinkMobile} {...sharedProps} />
						</div>
					</>
				)}
				{client && description && <div className="mobile-counter padx2 portrait">{counter}</div>}
      </div>
    ) : null
  )
}

export default Slide

export const SlideFragment = graphql`
	fragment SlideAsset on ContentfulSlideAsset {
		contentful_id
		videoLandscape {
			file {
				url
			}
		}
		videoPortrait {
			file {
				url
			}
		}
		imageLandscape {
			contentful_id
			file {
				contentType
				url
			}
			fluid(maxWidth: 1200, quality: 70) {
				...GatsbyContentfulFluid
			}
			gatsbyImageData(
				layout: FULL_WIDTH
				placeholder: DOMINANT_COLOR
				quality: 70
			)
		}
		imagePortrait {
			contentful_id
			file {
				contentType
				url
			}
			fluid(maxWidth: 1200, quality: 70) {
				...GatsbyContentfulFluid
			}
			gatsbyImageData(
				layout: FULL_WIDTH
				placeholder: DOMINANT_COLOR
				quality: 70
			)
		}
	}
`