// Dependencies
import React from 'react'
// Hooks
import useLocalStorage from '../hooks/useLocalStorage'

const CookieNotice = () => {

  const [hide, setHide] = useLocalStorage('hideCookieNotice', false);

  const yes = () => {
    !window.ga || window.ga('set', 'anonymizeIp', 0);
    setHide(true)
  }

  const no = () => {
    !window.gaOptout || window.gaOptout()
    setHide(true)
  }

  return (
    !hide && (
      <div className="cookie-notice padx2">
        <span>Allow cookies?</span>
        <button onClick={yes}>Yes</button>
        <button onClick={no}>No</button>
      </div>
    )
  )
}

export default CookieNotice
