// Dependencies
import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
// Hooks
import useSettings from "../hooks/useSettings";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
// Components
import Markdown from "../components/markdown";

const Copyright = ({ className = "" }) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className={className}>{`© ${year} ANY OTHER NAME`}</div>
  )
}

function useInfoContent() {
  const data = useStaticQuery(graphql`
    {
      allContentfulInfo {
        nodes {
          first: copyFirstColumn {
            childMarkdownRemark {
              html
            }
          }
          second: copySecondColumn {
            childMarkdownRemark {
              html
            }
          }
					third: copyThirdColumn {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulInfo.nodes[0]
}

const AboutContent = ({ children }) => {

  // Lock scrolling...
  useLockBodyScroll();

  return (
    children
  )
}

const AboutName = ({ onClick }) => {

	const [show, set] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			set(true)
		}, 100)	
		return () => clearTimeout(timer)
	}, [])

	return (
		<div className={`prel inlb about-name ${show ? 'show' : ''}`} onClick={onClick}>
			<div>ANY OTHER NAME</div>
			<div className="about-name-bar progress-bar" />
		</div>
	)
}

const About = ({ show, setShowAbout, invert }) => {

  // Content
  // const { about, servicesClients } = useSettings();
	const { first, second, third } = useInfoContent();

  // UX
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <AboutContent>
        <div
          className={`about ${invert ? 'invert' : ''}`}
          style={{
            animation: `${show ? "fadeIn" : "fadeOut"} 0.5s`
          }}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="about-grid padx2">
						<div className="col about-name-indent">
							<AboutName onClick={() => setShowAbout(false)} />
							<Markdown field={first} />
						</div>
						
            <div className="col">
              <Markdown field={second} />
              {/* <Copyright className="about-copyright padx2" /> */}
            </div>

						<div className="col">
              <Markdown field={third} />
              {/* <Copyright className="about-copyright padx2" /> */}
            </div>
          </div>
        </div>
      </AboutContent>
    )
  )
}

export default About
